<template>
    <v-app>
        <v-main transition="slide-x-transition">
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>

export default {
    name: 'App'
};
</script>

<style>

@font-face {
  font-family: "Apple Chancery";
  src: url('assets/fonts/Apple-Chancery-100.ttf');
}
a {
    color: white !important;
    text-decoration: none;
}
body{
    overflow: hidden;
}
</style>