<template>
  <div class="home">
    <div class="main">
      <transition name="fade">
        <v-container fill-height v-if="welcomeMsgShow" id="welcomeMsgBox"> 
          <v-layout align-center justify-center>
            <div id="msg" align-center justify-center>
              <p class="welcomeMsg">
                  <span class="hand-emoji">🙏🏾</span>
                  حق موجود
                  <span class="hand-emoji">🙏🏾</span>
              </p>
              <v-btn class="hideMsg" @click="hideMsg">سدا موجود</v-btn>
            </div>
          </v-layout>
        </v-container>
      </transition>
      <transition name="fade">
        <v-container fill-height v-if="backContentShow" id="content"> 
          <v-layout align-center justify-center>
            <div class="hand-img">
            <img src="../assets/homepage-hand-resized.png" v-if="img_small" alt="Hand-img" usemap="#image-map" id="image-id">

            <map name="image-map" v-if="img_small">
              <area alt="Chirmi" title="Chirmi" @click="ChirmiPopupShow" coords="146,17,173,54" shape="rect">
              <area alt="Akash Bayl" title="Akash Bayl" @click="$router.push('akash-bayl')" coords="67,274,27" shape="circle">
              <area alt="coal" @click="showCanvas" coords="120,404,31" shape="circle">
              <area alt="thuhar" title="thuhar" @click="$router.push('thuhar')" coords="170,332,194,345,193,365,197,383,198,400,182,404,167,381,160,361,162,339" shape="poly">
              <area alt="kandi" title="kandi" @click="openKandiPopup" coords="227,298,37" shape="circle">
              <area alt="arka" title="arka" @click="$router.push('arka')" coords="195,234,25" shape="circle">
              <area alt="peepal" title="peepal" @click="$router.push('peepal')" coords="207,152,25" shape="circle">
              <area alt="صحرا" title="صحرا" @click="sehraShow" coords="134,208,20" shape="circle">
            </map>

            <img src="../assets/homepage-hand-780x1050.png" 
            usemap="#image-mapx1920x1080" 
            class="res-1920x1080"
            v-if="img_1920">

            <map name="image-mapx1920x1080" v-if="img_1920">
                <area alt="Coal" @click="showCanvas" coords="311,949,73" shape="circle">
                <area alt="Thuhar" title="Thuhar" @click="$router.push('thuhar')" coords="399,785,474,932" shape="rect">
                <area alt="Akash Bayl" title="Akash Bayl" @click="$router.push('akash-bayl')" coords="161,637,67" shape="circle">
                <area alt="Arka" title="Arka" @click="$router.push('arka')" coords="474,547,65" shape="circle">
                <area alt="Kandi" title="Kandi" @click="openKandiPopup" coords="515,793,510,731,462,693,485,631,534,602,590,598,630,628,638,683,595,747,546,747" shape="poly">
                <area alt="peepal" title="peepal" @click="$router.push('peepal')" coords="502,350,53" shape="circle">
                <area alt="Chirmi" title="Chirmi" @click="ChirmiPopupShow" coords="405,93,56" shape="circle">
                <area alt="Sehrah" title="صحرا" @click="sehraShow" coords="337,485,39" shape="circle">
            </map>

            <img src="../assets/homepage-hand-555x750.png" 
            usemap="#image-mapx1366x768" 
            class="res-1366x768"
            v-if="img_1366">

            <map name="image-mapx1366x768" v-if="img_1366">
                <area alt="Thuhar" title="Thuhar" @click="$router.push('thuhar')" coords="283,561,336,668" shape="rect">
                <area alt="Coal" @click="showCanvas" coords="224,684,48" shape="circle">
                <area alt="Akash Bayl" title="Akash Bayl" @click="$router.push('akash-bayl')" coords="115,456,45" shape="circle">
                <area alt="Arka" title="Arka" @click="$router.push('arka')" coords="341,392,46" shape="circle">
                <area alt="Peepal" title="Peepal" @click="$router.push('peepal')" coords="358,250,37" shape="circle">
                <area alt="Kandi" title="Kandi" @click="openKandiPopup" coords="369,565,363,525,329,502,330,457,367,430,415,424,446,442,461,479,433,528,396,536" shape="poly">
                <area alt="Sehrah" title="صحرا" @click="sehraShow" coords="243,350,32" shape="circle">
                <area alt="Chirmi" title="Chirmi" @click="ChirmiPopupShow" coords="290,65,38" shape="circle">
            </map>

            <img src="../assets/homepage-hand-407x550.png" 
            usemap="#image-map"
            class="res-1200x600"
            v-if="img_1200">

            <map name="image-map" v-if="img_1200">
                <area alt="Akash Bayl" title="Akash Bayl" @click="$router.push('akash-bayl')" coords="84,335,35" shape="circle">
                <area alt="Thuhar" title="Thuhar" @click="$router.push('thuhar')" coords="208,412,247,492" shape="rect">
                <area alt="Coal" @click="showCanvas" coords="160,494,38" shape="circle">
                <area alt="Arka" title="Arka" @click="$router.push('arka')" coords="247,285,33" shape="circle">
                <area alt="Kandi" title="Kandi" @click="openKandiPopup" coords="270,414,256,383,234,343,271,310,300,306,319,318,339,340,316,385" shape="poly">
                <area alt="Peepal" title="Peepal" @click="$router.push('peepal')" coords="263,187,27" shape="circle">
                <area alt="Chirmi" title="Chirmi" @click="ChirmiPopupShow" coords="212,46,27" shape="circle">
                <area alt="Sehrah" title="صحرا" @click="sehraShow" coords="178,255,25" shape="circle">
            </map>


            <img src="../assets/homepage-hand-670x900.png" usemap="#image-map-670x900" v-if="img_900">

            <map name="image-map-670x900" v-if="img_900">
                <area  alt="Coal" @click="showCanvas" coords="259,812,65" shape="circle">
                <area  alt="Akash Bayl" @click="$router.push('akash-bayl')" title="Akash Bayl" coords="141,548,56" shape="circle">
                <area  alt="Thuhar" @click="$router.push('thuhar')" title="Thuhar" coords="337,705,347,758,381,805,404,808,414,781,393,747,414,725,399,671,354,672" shape="poly">
                <area  alt="Kandi" @click="openKandiPopup" title="Kandi" coords="443,676,440,626,405,601,412,543,448,519,491,513,531,528,550,553,551,585,527,630,482,645" shape="poly">
                <area  alt="Peepal" @click="$router.push('peepal')" title="Peepal" coords="435,301,43" shape="circle">
                <area  alt="Arka" @click="$router.push('arka')" title="Arka" coords="408,458,58" shape="circle">
                <area  alt="Chirmi" @click="ChirmiPopupShow" title="Chirmi" coords="321,27,388,123" shape="rect">
                <area  alt="Sehrah" @click="sehraShow" title="صحرا" coords="288,416,40" shape="circle">
            </map>


            <img src="../assets/homepage-hand-990x1350.png" usemap="#image-map-990x1350" v-if="img_1350">

            <map name="image-map-990x1350" v-if="img_1350">
                <area  alt="Coal" @click="showCanvas" coords="386,1215,83" shape="circle">
                <area  alt="Akash Bayl" @click="$router.push('akash-bayl')" title="Akash Bayl" coords="209,822,82" shape="circle">
                <area  alt="Thuhar" @click="$router.push('thuhar')" title="Thuhar" coords="500,1056,518,1139,556,1203,575,1213,605,1181,578,1109,615,1096,583,1003,528,1015" shape="poly">
                <area  alt="Kandi" @click="openKandiPopup" title="Kandi" coords="660,1019,639,949,592,897,619,799,711,770,791,786,810,821,815,865,747,974,698,960" shape="poly">
                <area  alt="Arka" @click="$router.push('arka')" title="Arka" coords="517,635,685,745" shape="rect">
                <area  alt="Peepal" @click="$router.push('peepal')" title="Peepal" coords="642,450,61" shape="circle">
                <area  alt="Chirmi" @click="ChirmiPopupShow" title="Chirmi" coords="475,42,574,183" shape="rect">
                <area  alt="Sehrah" @click="sehraShow" title="صحرا" coords="428,628,53" shape="circle">
            </map>

            <img src="../assets/homepage-hand-1098x1500.png" usemap="#image-map-1098x1500" v-if="img_1500">

            <map name="image-map-1098x1500" v-if="img_1500">
                <area alt="Akash Bayl" @click="$router.push('akash-bayl')" title="Akash Bayl" coords="232,915,88" shape="circle">
                <area alt="Peepal" @click="$router.push('peepal')" title="Peepal" coords="713,503,75" shape="circle">
                <area alt="Thuhar" @click="$router.push('thuhar')" title="Thuhar" coords="550,1176,571,1261,624,1342,646,1348,680,1304,641,1232,677,1220,637,1103" shape="poly">
                <area alt="Kandi" @click="openKandiPopup" title="Kandi" coords="730,1122,710,1046,661,989,690,887,797,857,828,866,908,928,833,1070" shape="poly">
                <area alt="Arka" @click="$router.push('arka')" title="Arka" coords="572,711,756,826" shape="rect">
                <area alt="Chirmi" @click="ChirmiPopupShow" title="Chirmi" coords="527,54,640,207" shape="rect">
                <area alt="Sehrah" @click="sehraShow" title="صحرا" coords="471,696,60" shape="circle">
                <area alt="Coal" @click="showCanvas" coords="422,1356,99" shape="circle">
            </map>


            <img src="../assets/homepage-hand-1319x1800.png" usemap="#image-map-1319x1800" v-if="img_1800">

            <map name="image-map-1319x1800" v-if="img_1800">
                <area alt="Akash Bayl" @click="$router.push('akash-bayl')" title="Akash Bayl" coords="280,1098,106" shape="circle">
                <area alt="Peepal" @click="$router.push('peepal')" title="Peepal" coords="857,604,85" shape="circle">
                <area alt="Thuhar" @click="$router.push('thuhar')" title="Thuhar" coords="665,1399,687,1521,757,1617,805,1576,772,1492,798,1458,768,1329" shape="poly">
                <area alt="Arka" @click="$router.push('arka')" title="Arka" coords="688,854,909,994" shape="rect">
                <area alt="Kandi" @click="openKandiPopup" title="Kandi" coords="872,1348,854,1258,775,1186,841,1075,954,1029,1038,1054,1081,1108,1081,1149,1003,1301" shape="poly">
                <area alt="Chirmi" @click="ChirmiPopupShow" title="Chirmi" coords="637,79,768,240" shape="rect">
                <area alt="Coal" @click="showCanvas" title="Coal" coords="503,1611,118" shape="circle">
                <area alt="Sehrah"  @click="sehraShow" title="Sehrah" coords="568,836,78" shape="circle">
            </map>

            </div>

            <!-- <transition name="fade">
              <div class="sehra-popup">
                <img src="../assets/sehra.png" width="500" height="500">
              </div>
            </transition> -->

            <transition name="modal">
              <div class="modal-mask" v-if="sehraPopupShow">
                <div class="modal-wrapper">
                  <div class="modal-container" v-bind:style="{ width: resWidth }">

                    <div class="modal-header">
                      <slot name="header">
                        <span class="header-text" v-if="sehraImg">the desert is a <span>mirror for the divine</span></span>
                        <button class="modal-cross" @click="hideSehra">X</button>
                      </slot>
                    </div>

                    <div class="modal-body">
                      <slot name="body">
                        <img id="sehraImg" src="../assets/sehra-600x450.jpg" v-bind:style="{ height: resHeight +'px' }" v-if="sehraImg"/>
                        <transition name="fade">
                          <div id="sehraCredits" v-bind:style="{ width:creditWidth+'px', height: resHeight +'px' }" v-if="sehraCredits">
                            <div id="sehraCreditsContent">
                              <h3>A garden among the flames!</h3>
                              <p><span style="font-style: italic">A garden among the flames!</span> is a space for contemplation, praise and wonder amidst the sacred and endangered ecology of the Thar Desert. Emerging from a research project titled Sehrapanthi this website creates moments for the contemplation of the multitudinous ecological forms that inhabit and imbue the desert with the power and the beauty of the divine. Against brutal, extractivist understandings of the value (or lack thereof) of the desert landscape, this space seeks to enable sacred and magical ways of seeing, knowing, and being with this ancient, mystical ecology to engage and activate its energies and powers (and those of our own) towards collective care, healing, expansion and struggle.</p>

                              <p><span style="font-style: italic">A garden among the flames!</span><br/>
                              by Zahra Malkani</p>

                              <p>Web development & coding by <a href="https://www.discretelogix.com" target="_blank">Discretelogix (Pvt) Ltd</a></p>

                              <p>With content from:<br/>
                              Akash Datwani (Akash Bayl)<br/>
                              Sabrina Toppa (Akh)</p>

                              <p>More information <a href="#" target="_blank">here</a>.</p>
                            </div>
                          </div>
                        </transition>
                      </slot>
                    </div>

                    <div class="modal-footer">
                      <slot name="footer">
                        <a @click="sehraShowCredits" class="footer-text">information</a>
                      </slot>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </v-layout>
        </v-container>
      </transition>

      <!-- <transition name="slide-fade"> -->
        <v-container fill-height v-if="chirmiPopupShow" id="chirmiPopup" style="display: none;"> 
          <v-layout align-center justify-center>
            <div id="msg" align-center justify-center style="position:relative">
              <span style="position: absolute;
                right: 5px;
                top: -25px;
                cursor: pointer;"
                @click="ChirmiPopupHide">x</span>
              <p class="chirmiPopup" 
              v-html= (chirmiMsg[chirmiOrder].msg)>
              </p>
            </div>
          </v-layout>
        </v-container>
      <!-- </transition> -->

      <transition name="fade">
        <v-container fill-height v-if="kandiPopup" id="welcomeMsgBox" 
        style="position: absolute;top: 0;"> 
          <v-layout align-center justify-center>
            <div id="msg" align-center justify-center style="max-width: 465px">
              <p class="welcomeMsg" style="font-family: inherit;
              font-size: 18px;
              font-weight: 400;
              font-style: italic;">
                I greeted the Tree and it answered, greeting me even more finely. It said: “Listen, O wayfarer, O beloved.” 
              </p>
              <v-btn class="hideMsg" @click="$router.push('kandi')" style="font-family: inherit;    text-transform: capitalize; font-weight: 400; height: 30px; padding: 10px 20px 10px; letter-spacing: normal;">Listen</v-btn>
            </div>
          </v-layout>
        </v-container>
      </transition>
      <transition name="fade">
        <canvas id="c" v-if="coalCanvas" @mousemove="hoverCanvas" @mousedown="clickCanvas"></canvas>
      </transition>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

//import imageMapResizer from 'image-map-resizer';
export default {
  name: "Home",
  data() {
    return {
      welcomeMsgShow: false,
      backContentShow: false,
      sehraPopupShow: false,
      chirmiPopupShow: false,
      kandiPopup: false,
      img_1200: false,
      img_1366: false,
      img_1920:false,
      img_900:false,
      img_1350: false,
      img_1500: false,
      img_1800: false,
      img_small: false,
      chirmiMsg: [
        {id: 1, msg: "A garden among the flames!<br/>My heart can take on any form.<br/>A meadow for gazelles, a monastery for monks,<br/>For the idols, it is temple<br/>For pilgrims it is a Kaaba<br/>Such is my faith."},
        {id: 2, msg: "See her in a tree and see her in a stone."},
        {id: 3, msg: "A body alone<br/>Could know the plight of a body in anguish."},
        {id: 4, msg: "I was the hidden treasure and I loved to be known, I created all creation so that I may be known."},
        
      ],
      coalCanvas: false,
      resHeight: 450,
      resWidth: 'max-content',
      creditWidth: 600,
      sehraCredits: false,
      sehraImg: true,
      chirmiOrder: -1,
      offsetX : 0.242,
      offsetY : 0.244,
      chirmiInterval: "",
      ChirmiFadeInterval: ""
    };
  },
  computed: {},
  mounted(){
    if(this.$store.state.welcomeMsg == true){
      this.showMsg();
    }
    else{
      this.showContent();
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    showContent(){
      this.showHand();
      this.backContentShow = true;
    },
    showMsg(){
      var _this = this;
      document.fonts.ready.then(function () {
        setTimeout(() => _this.welcomeMsgShow = true, 500);
      });
    },
    hideMsg(){
      this.$store.state.welcomeMsg = false;
      this.welcomeMsgShow = false;
      this.showContent();
    },
    showHand(){
      if(window.innerWidth < 1200 ){
        this.img_1200 = false;
        this.img_1366 = false;
        this.img_900 = false;
        this.img_1920 = false;
        this.img_1350 = false;
        this.img_1500 = false;
        this.img_1800 = false;
        this.img_small = true;
      }
      else if(window.innerHeight >= 600 && window.innerHeight < 800 && window.innerWidth >= 1200){
        this.img_1366 = false;
        this.img_900 = false;
        this.img_1920 = false;
        this.img_1350 = false;
        this.img_1500 = false;
        this.img_1800 = false;
        this.img_small = false;
        this.img_1200 = true;
      }
      else if(window.innerHeight >= 800 && window.innerHeight < 960 && window.innerWidth >= 1200 ){
        this.img_1200 = false;
        this.img_900 = false;
        this.img_1920 = false;
        this.img_1350 = false;
        this.img_1500 = false;
        this.img_1800 = false;
        this.img_small = false;
        this.img_1366 = true
      }
      else if(window.innerHeight >= 960 && window.innerHeight < 1100 && window.innerWidth >= 1200){
        this.img_1200 = false;
        this.img_1366 = false;
        this.img_1920 = false;
        this.img_1350 = false;
        this.img_1500 = false;
        this.img_1800 = false;
        this.img_small = false;
        this.img_900 = true;
      }
      else if(window.innerHeight >= 1100 && window.innerHeight < 1400 && window.innerWidth >= 1200){
        this.img_1200 = false;
        this.img_1366 = false;
        this.img_900 = false;
        this.img_1350 = false;
        this.img_1500 = false;
        this.img_1800 = false;
        this.img_small = false;
        this.img_1920 = true;
      }
      else if(window.innerHeight >= 1400 && window.innerHeight < 1550 && window.innerWidth >= 1200){
        this.img_1200 = false;
        this.img_1366 = false;
        this.img_900 = false;
        this.img_1920 = false;
        this.img_1500 = false;
        this.img_1800 = false;
        this.img_small = false;
        this.img_1350 = true;
      }
      else if(window.innerHeight >= 1550 && window.innerHeight < 1850 && window.innerWidth >= 1200){
        this.img_1200 = false;
        this.img_1366 = false;
        this.img_900 = false;
        this.img_1920 = false;
        this.img_1350 = false;
        this.img_1800 = false;
        this.img_small = false;
        this.img_1500 = true;
      }
      else if(window.innerHeight >= 1850 && window.innerWidth >= 1200){
        this.img_1200 = false;
        this.img_1366 = false
        this.img_900 = false;
        this.img_1920 = false;
        this.img_1350 = false;
        this.img_1500 = false;
        this.img_small = false;
        this.img_1800 = true;
      }
      else{
        this.img_1200 = false;
        this.img_1366 = false
        this.img_900 = false;
        this.img_1920 = false;
        this.img_1350 = false;
        this.img_1500 = false;
        this.img_1800 = false;
        this.img_small = true;
      }
    },
    myEventHandler() {
      this.showHand();
      this.OffsetCanvas();
      this.resHeight = (window.innerHeight - 100);
    },
    sehraShow(){
      this.sehraPopupShow = true;
      if(window.innerWidth > window.innerHeight + 300){
        this.resHeight = (window.innerHeight - 100);
      }
      //this.resWidth = document.getElementById('sehraImg').getBoundingClientRect().width;
    },
    hideSehra(){
      this.sehraPopupShow = false;
      this.sehraCredits = false;
      this.sehraImg = true;
    },
    sehraShowCredits(){
      this.creditWidth = (document.getElementById('sehraImg').getBoundingClientRect().width);
      this.sehraImg = false;
      this.sehraCredits = true;
    },
    ChirmiPopupShow(){
      
      if((this.chirmiOrder+1) < this.chirmiMsg.length ){
        this.chirmiOrder = this.chirmiOrder + 1;
      }else{
        this.chirmiOrder = 0;
      }
      this.chirmiPopupShow = true;
      setTimeout(() => $("#chirmiPopup").fadeIn(1000), 10);
      this.ChirmiFadeInterval = setTimeout(() => $("#chirmiPopup").fadeOut(3000), 4000);
      this.chirmiInterval = setTimeout(() => this.chirmiPopupShow = false, 7000);
    },
    ChirmiPopupHide(){
      clearTimeout(this.ChirmiFadeInterval);
      clearTimeout(this.chirmiInterval);
      //document.getElementById('chirmiPopup').style.display = "none";
      this.chirmiPopupShow = false;
    },
    showCanvas(){
      this.coalCanvas = true
    },
    OffsetCanvas(){
      if(window.screen.width >= 1280 && window.screen.width < 1440 && window.screen.height >= 854 && window.screen.height < 960){
        this.offsetX = 0.232;
        this.offsetY = 0.210;
      }
      else if(window.screen.width >= 1440 && window.screen.width < 2560 && window.screen.height >= 960 && window.screen.height < 1440){
        this.offsetX = 0.210;
        this.offsetY = 0.180;
      }
      else if(window.screen.width >= 2560 && window.screen.width < 2880 && window.screen.height >= 1440 && window.screen.height < 1800){
        this.offsetX = 0.120;
        this.offsetY = 0.110;
      }
      else if(window.screen.width >= 2880 && window.screen.width < 5120 && window.screen.height >= 1800  && window.screen.height < 2880){
        this.offsetX = 0.105;
        this.offsetY = 0.09;
      }
      else if(window.screen.width >= 5120 && window.screen.width < 6016 && window.screen.height >= 2880 && window.screen.height < 3384){
        this.offsetX = 0.060;
        this.offsetY = 0.055;
      }
      else if(window.screen.width >= 6016 && window.screen.height >= 3384){
        this.offsetX = 0.05;
        this.offsetY = 0.05;
      }
      else{
        this.offsetX = 0.222;
        this.offsetY = 0.242;
      }
    },
    hoverCanvas(e){
      var el = document.getElementById('c');
      var ctx = el.getContext('2d');
      var clientX, clientY;
      //var density = 100;
      //ctx.lineJoin = ctx.lineCap = 'round' 0.222 and 0.242;
      clientX = e.clientX;
      clientY = e.clientY;
      // if(window.screen.width <= 1366){
      //   var displx = 0.222;
      //   var disply = 0.242;
      //   console.log(displ)
      // }
      this.OffsetCanvas();
      var offX = this.offsetX;
      var offY = this.offsetY;
      
      //console.log(offX+'/'+offY);
      var x = clientX*offX;
      var y = clientY*offY;
      ctx.lineWidth = 10;
      ctx.lineJoin = ctx.lineCap = 'round';
      ctx.shadowBlur = 10;
      ctx.shadowColor = 'rgb(0, 0, 0)';
      ctx.moveTo(x, y);
      ctx.lineTo(x, y);
      ctx.stroke();
    },
    clickCanvas(){
      this.coalCanvas = false;
    },
    openKandiPopup(){
      this.kandiPopup = true;
    }
  }
};
</script>
<style scoped>



.hand-img{
  position: fixed;
  bottom: 0;
}
.main{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url('../assets/bg-img.jpg') no-repeat center center;
  background-size: cover;
  background-color: #dcdcdc;
  transform: scale(1.0);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: opacity 1s ease-in;
  /* transition: opacity 1s ease-in, translate 1s ease-in; */
}
.slide-fade-leave-active {
  transition: opacity 3s ease-out;
  /* transition: opacity 3s ease-out, translate 3s ease-out; */
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  /* transform: translateX(10px); */
  opacity: 0;
}
#msg{
  min-width: 250px;
  text-align: center;
  min-height: 100px;
  background: #FFFFFF;
  padding: 5px 15px 15px;
  border: 5px solid #D3E2EC;
  border-top-width: 25px;
  border-radius: 5px;
}
p.welcomeMsg{
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Noto Nastaliq Urdu', serif;
}
#msg button{
  height: initial;
  min-width: 90px;
  font-weight: 600;
  font-family: 'Noto Nastaliq Urdu', serif;
  font-size: 17px;
  padding: 5px 20px 17px;
  height: 30px;
}
p .hand-emoji{
  font-size: 20px;
}
#chirmiPopup{
  position: fixed;
  top: 0;
}
#chirmiPopup #msg{
  min-height: auto;
  max-width: 400px;
}
p.chirmiPopup{
  text-align: left;
  margin-bottom: 0px;
}
#welcomeMsgBox, #chirmiPopup{
  max-width: 100%;
  background-color: transparent;
}
#content{
  padding: 75px;
}
map area{
  cursor: pointer;
}


.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:transparent;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  transition: all 0.3s ease;
}
.modal-body{
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  background-color: transparent;
  line-height: 0;
}
.modal-header .header-text, .modal-footer .footer-text{
  position: relative;
  background: white;
  width: max-content;
  padding: 5px 15px;
  font-family: "Apple Chancery";
  cursor: pointer;
  color: #807665 !important;
}

.modal-header .header-text {
  top: 12px;
}
.modal-header .header-text span{
  transition: all 0.5s;
}
.modal-header .header-text:hover span{
  text-shadow: 3px 5px 2px rgba(0,0,0,0.7);
}
.modal-footer .footer-text{
  bottom: 15px;
  color: #0000FF !important;
}
.modal-footer, .modal-header{
  text-align: center;
}

.modal-cross {
  position: relative;
  float: right;
  background: white;
  padding: 4px;
  top: 14px;
  right: -7px;
  line-height: 10px;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

canvas { 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;  
}

#sehraCredits{
  background-color: #fff;
  padding: 30px;
  overflow-y: auto;
}
#sehraCredits h3, #sehraCredits p{
  line-height: initial;
  font-family: 'Arial', sans-serif;
}
#sehraCredits h3{
  color: #0000FF;
  margin-bottom: 10px;
  font-size: 16px;
  font-style: italic;
}
#sehraCredits p{
  font-size: 14px;
  margin-bottom: 10px;
}
#sehraCredits p a{
  color: #0000FF !important;
}
#sehraCredits p a:hover{
  text-decoration: underline;
}
</style>
